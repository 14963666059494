import React from 'react';
import { Components } from 'sitedata';
import IndexLayout from 'layouts';
import TopSection from 'views/AgentPage/TopSection';
import AgentPage from 'views/AgentPage/AgentPage';
import styled from '@emotion/styled';

const Member = () => (
  <IndexLayout>
    <Components.Header />
    <TopSection />
    <StyledMemberWrapper className="member">
      <AgentPage />
    </StyledMemberWrapper>
    <Components.Footer />
  </IndexLayout>
);
export default Member;

const StyledMemberWrapper = styled.div`
  max-width: 1410px;
  margin: 0 auto;
  padding: 32px 38px;

  // import FeaturedSection from 'views/HomePage/FeaturedSection';
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 32px 16px;
  }
`;
