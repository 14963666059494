import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useLocation } from '@reach/router';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { getAgentById, getAgentReviews } from './api';

interface AgentPageContextValues {
  agentId: number | null | undefined;
  user: AgentInfo | null;
  reviews?: Review[];
  setReviews: React.Dispatch<React.SetStateAction<Review[] | undefined>>;
}

const AgentPageContext = React.createContext({} as AgentPageContextValues);

export const AgentPageContextWrapper = ({ children }) => {
  const [agentId] = useQueryParam('id', NumberParam);
  const locationState = useLocation().state as AgentInfo | null;
  const [user, setUser] = useState<AgentInfo | null>(locationState);
  const [reviews, setReviews] = useState<Review[]>();

  useEffect(() => {
    if (!agentId) {
      navigate('/our-team');
      return;
    }
    if (user) return;
    (async () => {
      try {
        const user = await getAgentById(agentId);
        setUser(user);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [agentId, user]);

  useEffect(() => {
    if (!agentId || reviews) return;
    (async () => {
      try {
        const reviews = await getAgentReviews(agentId);
        setReviews(reviews);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [agentId, reviews]);

  const contextValues = {
    agentId,
    user,
    reviews,
    setReviews
  };

  return <AgentPageContext.Provider value={contextValues}>{children}</AgentPageContext.Provider>;
};

export default AgentPageContext;
