import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { Image } from 'components';
import placeholder from 'assets/images/avatar-placeholder.jpg';
interface AgentCardProps extends React.HTMLAttributes<HTMLDivElement> {
  data: AgentInfo;
}

const defaultAvatar = <img src={placeholder} className="agent-card__photo img-error" alt="no image" />;

const AgentCard: React.FC<AgentCardProps> = ({ className, data, ...props }) => {
  return (
    <StyledAgentCard className={clsx('agent-card', className)} {...props}>
      <div className="agent-card__photo-block">
        {data.avatar ? (
          <Image
            src={data.avatar}
            error={defaultAvatar}
            className="agent-card__photo"
            alt="member-photo"
          />
        ) : defaultAvatar}
      </div>
      <div className="agent-card__info">
        <p className="agent-card__name">{data.name}</p>
        <p className="agent-card__position">{data.position}</p>
        <a href={`mailto: ${data.email}`} className="agent-card__email">
          {data.email}
        </a>
        <p className="agent-card__description">{data.description}</p>
      </div>
    </StyledAgentCard>
  );
};

const StyledAgentCard = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  margin: 0 0 32px;
  width: 100%;
  .agent-card {
    &__photo-block {
      width: 320px;
      height: 320px;
      margin: 0 24px 0 0;
      border-radius: ${props => props.theme.misc.borderRadius};
      overflow: hidden;
      flex-shrink: 0;
    }
    &__photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__info {
      max-width: 450px;
    }
    &__description {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 20px;
    }
    &__name {
      margin: 0 0 8px;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
    }
    &__position {
      margin: 0 0 20px;
      font-size: 14px;
      line-height: 16px;
    }
    &__email {
      font-size: 14px;
      line-height: 16px;
      color: ${props => props.theme.colors.primary};
      text-decoration: none;
      margin: 0 0 40px;
      display: block;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    margin: 0 0 24px;
    .agent-card {
      &__photo-block {
        width: 100%;
        margin: 0 0 16px;
      }
      &__info {
        max-width: 100%;
      }
      &__name,
      &__position,
      &__email {
        text-align: center;
      }
      &__name {
        font-size: 14px;
        line-height: 24px;
      }
      &__position {
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 12px;
      }
      &__email {
        font-size: 12px;
        line-height: 16px;
        margin: 0;
      }
      &__description {
        margin: 0;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    box-shadow: none;
  }
`;

export default AgentCard;
