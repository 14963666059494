import React, { useEffect, useMemo, useState } from 'react';
import LazyLoad from 'react-lazyload';
import styled from '@emotion/styled';
import clsx from 'clsx';
import notify from 'notify';
import { Button, ListingCard } from 'components';
import { getAgentListings } from './api';

const maxListings = 6;

const MyListings = ({ agentId }) => {
  const [listings, setListings] = useState<ListingCardData[]>([]);
  const [listingsStatus, setListingsStatus] = useState<ListingStatus>('Active');

  const filteredListings = useMemo(() => {
    const result = listings.filter(item => item.status === listingsStatus).slice(0, maxListings);
    return result;
  }, [listings, listingsStatus]);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const data = await getAgentListings(agentId);
        setListings(data as any);
      } catch (err) {
        notify(`Couldn't fetch agent's listings`);
      }
    };

    fetchListings();
  }, [agentId]);

  if (!listings.length) return null;

  return (
    <StyledPropertiesSection className="properties-section">
      <div className="properties-section__wrapper">
        <h4 className="properties-section__title">My listings</h4>
        <hr />
        <div className="properties-section__controls">
          <Button
            className={clsx({ active: listingsStatus === 'Active' })}
            onClick={() => setListingsStatus('Active')}>
            Active
          </Button>
          <Button
            className={clsx({ active: listingsStatus === 'Closed' })}
            onClick={() => setListingsStatus('Closed')}>
            Closed
          </Button>
          {/* <Button className="see-all" link>
            See all {'>'}
          </Button> */}
        </div>
        <div className="properties-list" id="lazy-scroll-container'">
          {filteredListings.length !== 0 ? (
            filteredListings.map(item => (
              <LazyLoad
                key={item.id}
                height={276}
                scrollContainer={document.querySelector('#lazy-scroll-container') || undefined}>
                <ListingCard data={item} />
              </LazyLoad>
            ))
          ) : (
            <p>No listings</p>
          )}
        </div>
      </div>
    </StyledPropertiesSection>
  );
};

const StyledPropertiesSection = styled.div`
  padding: 0 0 28px;
  display: flex;
  justify-content: center;
  .properties-section {
    &__wrapper {
      max-width: 1334px;
      flex: 1;
    }
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.2;
      color: ${props => props.theme.colors.black};
      margin: 0;
    }
    &__controls {
      display: flex;
      margin: 18px 0 32px 0;
      .button {
        width: 144px;
        height: 39px;
        margin-right: 12px;

        &.active {
          background: black;
          color: white;
        }
      }

      .see-all {
        width: auto;
        margin: 0 0 0 auto;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  .properties-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    max-width: 1334px;
    justify-content: center;

    .property-card {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 0 40px;
    .properties-list {
      grid-template-columns: repeat(2, minmax(360px, 1fr));
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .properties-list {
      grid-template-columns: 1fr;
    }
    .properties-section {
      &__title {
        font-size: 16px;
      }
      &__controls {
        .button {
          width: 100%;
          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }
`;

export default MyListings;
