import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import useLayout from 'hooks/useLayout';
import { Button, Icon, Modal } from 'components';
import AgentCard from './AgentCard';
import ReviewCard from './ReviewCard';
import AgentContactForm from './AgentForm';
import AgentPageContext, { AgentPageContextWrapper } from './context';
import MyListings from './MyListings';

const AgentPage = () => {
  const { user, reviews } = useContext(AgentPageContext);
  const [showAllReviews, setShowAllReviews] = useState<boolean>(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const layout = useLayout();
  // eslint-disable-next-line no-nested-ternary
  const visibleReviews = showAllReviews
    ? reviews
    : layout === 'mobile'
    ? reviews?.slice(0, 2)
    : reviews?.slice(0, 5);

  return (
    <StyledAgentPage>
      <Link to="/our-team" className="link-back">
        <Icon name="arrow-simple" />
        <span className="link-back__text">Back</span>
      </Link>
      <div className="agent-wrapper">
        <div className="agent-container">
          {user && <AgentCard data={user} />}
          {visibleReviews && visibleReviews.length !== 0 && (
            <>
              <h2 className="clients-title">Clients are saying</h2>
              {visibleReviews.map(({ id, ...props }) => {
                return <ReviewCard {...props} key={id} />;
              })}
            </>
          )}
          {!showAllReviews && (
            <Button simple className="see-more-btn" onClick={() => setShowAllReviews(true)}>
              + See more
            </Button>
          )}
        </div>
        {user && layout === 'desktop' && (
          <AgentContactForm className="contact-form" agentInfo={user} />
        )}
      </div>
      {user && (
        <>
          <MyListings agentId={user.id} />
          {(layout === 'mobile' || layout === 'tablet') && (
            <>
              <div className="contact-buttons">
                <Button primary onClick={() => setOpenContactModal(true)}>
                  Contact me
                </Button>
                <Button primary outline onClick={() => setOpenContactModal(true)}>
                  Review Me
                </Button>
              </div>
              <StyledModal
                className="contact-agent-modal"
                open={openContactModal}
                onClose={() => setOpenContactModal(false)}>
                <AgentContactForm className="contact-form" agentInfo={user} />
              </StyledModal>
            </>
          )}
        </>
      )}
    </StyledAgentPage>
  );
};

export default () => (
  <AgentPageContextWrapper>
    <AgentPage />
  </AgentPageContextWrapper>
);

const StyledAgentPage = styled.div`
  .agent-wrapper {
    display: grid;
    grid-template-columns: 1fr 360px;
    grid-gap: 0 54px;
    margin: 32px 0 37px;
    align-items: flex-start;
    grid-template-areas: 'info form';
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      grid-template-columns: 1fr;
      grid-template-areas: 'info' 'form';
    }
  }
  .agent-container {
    grid-area: info;
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      margin: 0 0 32px;
    }
  }
  .contact-form {
    grid-area: form;
  }

  .link-back {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 32px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.primary};
    &__text {
      margin: 0 0 0 4px;
    }
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }

  .contact-title {
    font-size: 12px;
    line-height: 20px;
    color: ${props => props.theme.colors.dustyGray};
    margin: 0 0 12px;
  }
  .clients-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 24px;
  }

  .see-more-btn {
    font-size: 14px;
    color: ${props => props.theme.colors.primary};
  }

  .contact-buttons {
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
    padding: 12px 16px;
    margin: 0 -16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 12px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .agent-wrapper {
      margin: 32px 0;
    }
    .agent-container {
      margin: 0;
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 20px 12px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    &.contact-agent-modal {
      .contact-form {
        margin: 0;
      }
    }
  }
`;
