import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Rating } from 'components';

interface ReviewCard extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  createdOn: number;
  description: string;
  rating: number;
}

const ReviewCard: React.FC<ReviewCard> = ({
  name,
  createdOn,
  rating,
  description,
  className,
  ...props
}) => {
  return (
    <StyledReviewCard className={clsx('review-card', className)} {...props}>
      <Rating value={rating} readonly />
      <div className="info-block">
        <p className="info-block__author">{name}</p>
        <p className="info-block__date">{dayjs(createdOn).format('D MMM YYYY')}</p>
      </div>
      <p className="review">{description}</p>
    </StyledReviewCard>
  );
};

const StyledReviewCard = styled.div`
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: ${props => props.theme.misc.borderRadius};
  padding: 20px 16px;
  margin: 12px 0;

  .rating {
    margin-bottom: 16px;
  }

  .info-block {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
    flex-wrap: wrap;
    &__author,
    &__date {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
    &__author {
      position: relative;
      margin: 0 18px 0 0;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(50%, -50%);
        right: -9px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #000000;
      }
    }
    &__date {
      color: ${props => props.theme.colors.dustyGray};
    }
  }
  .review {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
`;

export default ReviewCard;
