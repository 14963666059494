import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, Rating } from 'components';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { AuthContext, AuthModalContext } from 'auth';
import { submitAgentReview } from '../api';
import AgentPageContext from '../context';

interface AgentReviewFormProps {
  agentId: number;
}

const AgentReviewForm: React.FC<AgentReviewFormProps> = ({ agentId }) => {
  const { authorized } = useContext(AuthContext);
  const { setAuthModalOpen } = useContext(AuthModalContext);
  const { setReviews } = useContext(AgentPageContext);
  const { register, control, errors, reset, handleSubmit } = useForm();

  const handleClick = e => {
    if (!authorized) {
      e.preventDefault();
      setAuthModalOpen(true);
    }
  };

  const submit = async form => {
    try {
      const reviews = await submitAgentReview({ ...form, agentId });
      reset({ description: '', rating: 0 });
      setReviews(reviews);
      notify('Reivew submitted');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <p className="contact-title">Rate me</p>
      <Controller
        control={control}
        name="rating"
        defaultValue={0}
        render={controllerProps => <Rating {...controllerProps} />}
      />
      <p className="contact-title">Your review</p>
      <Input
        ref={register({ required: 'Required' })}
        name="description"
        error={errors.message}
        placeholder="Your message"
        as="textarea"
      />
      <Button primary onClick={handleClick}>
        Send
      </Button>
    </StyledForm>
  );
};

export default AgentReviewForm;

const StyledForm = styled.form`

  .input {
    width: 100%;
    margin-bottom: 8px;

    input {
      height: 36px;
    }

    textarea {
      height: 136px;
      resize: vertical;
    }
  }

  .button {
    height: 36px;
    width: 100%;
    margin-top: 16px;
  }
`;
