import React, { useState } from 'react';

import styled from '@emotion/styled';
import { Tabs, Icon } from 'components';
import clsx from 'clsx';
import AgentContactForm from './AgentContactForm';
import AgentReviewForm from './AgentReviewForm';

type TabKey = 'contact' | 'review';

type Tab = { key: TabKey; text: string };

const tabs: Tab[] = [
  { key: 'contact', text: 'Contact Me' },
  { key: 'review', text: 'Review Me' }
];

interface AgentContactFormProps extends React.HTMLAttributes<HTMLDivElement> {
  agentInfo: AgentInfo;
}

const AgentForm: React.FC<AgentContactFormProps> = ({ className, agentInfo, ...props }) => {
  const [activeTab, setActiveTab] = useState<TabKey>('contact');

  const getTabContent = (key: TabKey) => {
    switch (key) {
      case 'contact':
        return (
          <>
            <p className="contact-title">Contacts</p>
            <div className="contacts-block">
              <div className="info-row">
                <Icon name="email" />
                <a href={`mailto:${agentInfo.email}`} className="link">
                  {agentInfo.email}
                </a>
              </div>
              <div className="info-row">
                <Icon name="phone" />
                <a href={`tel:${agentInfo.phone}`} className="link">
                  {agentInfo.phone}
                </a>
              </div>
              <div className="info-row">
                <Icon name="pin-mini" />
                {agentInfo.address}
              </div>
            </div>
            <AgentContactForm agentId={agentInfo.id} />
          </>
        );
      case 'review':
        return <AgentReviewForm agentId={agentInfo.id} />;
      default:
        return null;
    }
  };
  return (
    <StyledAgentContactForm className={clsx('agent-form', className)} {...props}>
      <StyledTabs
        activeTab={activeTab}
        onSelect={(_e, value) => setActiveTab(value.key)}
        items={tabs}
      />
      <div className="agent-form">{getTabContent(activeTab)}</div>
    </StyledAgentContactForm>
  );
};

export default AgentForm;

const StyledAgentContactForm = styled.div`
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: ${props => props.theme.misc.borderRadius};
  padding: 12px;

  .info-row {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    color: #000000;
    word-wrap: break-word;
    font-size: 14px;
    line-height: 16px;
    .icon {
      margin: 0 12px 0 0;
      flex-shrink: 0;
    }
    .link {
      text-decoration: none;
      color: unset;
    }
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }
  .contacts-block {
    margin: 0 0 24px;
  }

  .contact-title {
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 8px;
    color: #979797;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0;
    border: 0;
  }
`;

const StyledTabs = styled(Tabs)`
  margin: 0 0 12px;
  .tab-item {
    border: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    &.active {
      border: 0;
    }
  }
`;
